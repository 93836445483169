
import OrganismPageLoader from '~/components/organisms/common/OrganismPageLoader.vue'
import { smoothScrollToTargetId } from '~/helpers/util'
import baseConstants from '~/store/base/-constants'

export default {
  name: 'StaticLayout',
  components: { OrganismPageLoader },

  computed: {
    projects() {
      return this.$store.state.project.projects
    },
    sections() {
      return this.currentStaticPage.sections
    },
    currentStaticPage() {
      return this.generalConfig.staticPages.find(p => p.path === this.$route.path);
    },
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    customLinks() {
      if (!this.generalConfig.customLinks) return ''
      return this.generalConfig.customLinks
    },
    instagramUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.instagramUrl
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.linkedinUrl
    },
    facebookUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.facebookUrl
    },
    staticPages() {
      return this.generalConfig.staticPages || []
    },
    portfolioPageDisabled() {
      return this.generalConfig.portfolioPageDisabled || false
    },
    isStaticProjectPage() {
      return !!this.projectStaticPage && this.projectStaticPage.template === 'Project';
    },
    menuSectionsTags() {
      if (this.isStaticProjectPage) {
        return this.projectStaticPage.sections.filter((s) => s.menuLabel);
      }
      return this.sections.filter((s) => s.menuLabel)
    },
    isSidebarOpen() {
      return this.$store.state[baseConstants.namespace].sidebarOpen
    }
  },
  methods: {
    smoothScroll(e) {
      e.preventDefault();
      document.querySelector(`#${e.target.href.split('#')[1]}`).scrollIntoView({
        behavior: 'smooth'
      })
      try{
        const links = this.$el.querySelectorAll('a.navigation-link[href*="#"]')
        links.forEach((link) => {
          const activeLink = e.path[0];
          link.classList.remove('active')
          if (link === activeLink && !activeLink.classList.contains('active')) {
            link.classList.add('active')
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    hashChecker(target) {
      if (target === window.location.hash) {
        smoothScrollToTargetId(target)
      }
    }
  }
}
