
import OrganismPageLoader from "~/components/organisms/common/OrganismPageLoader.vue";

export default {
  name: "BaseLayout",
  components: {OrganismPageLoader},
  computed: {
    projects() {
      return this.$store.state.project.projects
    },
    sections() {
      return this.$store.state.base.meta.landingConfig.meta.sections
    },
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    customLinks() {
      if (!this.generalConfig.customLinks) return ''
      return this.generalConfig.customLinks
    },
    instagramUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.instagramUrl
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.linkedinUrl
    },
    facebookUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.facebookUrl
    },
    staticPages() {
      return this.generalConfig.staticPages || []
    },
    portfolioPageDisabled() {
      return this.generalConfig.portfolioPageDisabled || false
    }
  }
}
