
import OrganismPageLoader from "~/components/organisms/common/OrganismPageLoader.vue";
import {smoothScrollToTargetId} from "~/helpers/util";
import baseConstants from '~/store/base/-constants'

export default {
  name: 'LandingLayout',
  components: {OrganismPageLoader},
  computed: {
    projects() {
      return this.$store.state.project.projects
    },
    sections() {
      return this.$store.state.base.meta.landingConfig.sections
    },
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    customLinks() {
      if (!this.generalConfig.customLinks) return ''
      return this.generalConfig.customLinks
    },
    instagramUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.instagramUrl
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.linkedinUrl
    },
    facebookUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.facebookUrl
    },
    staticPages() {
      return this.generalConfig.staticPages || []
    },
    portfolioPageDisabled() {
      return this.generalConfig.portfolioPageDisabled || false
    },
    isSidebarOpen() {
      return this.$store.state[baseConstants.namespace].sidebarOpen
    }
  },
  // TODO: adjust the layout to support universal landing pages/ portfolio pages
  // watch: {
  //   $route: {
  //     deep: true,
  //     handler: function (to, from) {
  //       this.sortSections()
  //     }
  //   }
  // },
  mounted() {
    document.addEventListener('scroll', this.initScrollListener)

    // this.sortSections()
    const self = this
    document.querySelectorAll('a.navigation-link[href*="#"]').forEach((anchor) => {
      anchor.addEventListener('click', self.smoothScroll)
    })
    if (this.$route.hash !== '') {
      document.querySelector(this.$route.hash).scrollIntoView({
        behavior: 'smooth'
      })
    }
  },
  methods: {
    initScrollListener() {
      this.isScrolled = window.scrollY > window.innerHeight
    },
    backToTop() {
      smoothScrollToTargetId(`#building`)
    },
    hashChecker(target) {
      if (target === window.location.hash) {
        smoothScrollToTargetId(target)
      }
    }
  }
}
