import { render, staticRenderFns } from "./static.vue?vue&type=template&id=678d3c0e&"
import script from "./static.vue?vue&type=script&lang=js&"
export * from "./static.vue?vue&type=script&lang=js&"
import style0 from "./static.vue?vue&type=style&index=0&id=678d3c0e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/ProjectTemplate.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Flayouts%2Fstatic.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsOrganismMenuBarStatic: require('/app/components/organisms/OrganismMenuBarStatic.vue').default,OrganismsOrganismSidebar: require('/app/components/organisms/OrganismSidebar.vue').default})
