import { ActionContext } from 'vuex'
import constants from './-constants'

const {
  CHANGE_LANGUAGE,
  TOGGLE_SIDEBAR,
  CHANGE_SIDEBAR_STATE,
  CLOSE_SIDEBAR_STATE,
  TOGGLE_FILTER,
  SHOW_FILTER,
  TOGGLE_INFO,
  CLOSE_FILTER,
  CHANGE_FILTER_STATE,
  LOAD_METADATA,
  CHANGE_PAGE_LOADING_STATE,
  TOGGLE_MOBILE_AVAILABILITY_MENU,
  CHANGE_MOBILE_AVAILABILITY_MENU,
  CHANGE_DOCUMENT_READY_STATE,
  TOGGLE_SHOW_LEASED_SPACES
} = constants.action
const {
  SET_LANGUAGE,
  SET_METADATA,
  SET_SIDEBAR_STATE,
  SET_FILTER_STATE,
  SET_PAGE_LOADING_STATE,
  SET_MOBILE_AVAILABILITY_MENU,
  SET_DOCUMENT_READY_STATE,
  SET_SHOW_LEASED_SPACES
} = constants.mutation

export default {
  [CHANGE_LANGUAGE]({ commit, state }: ActionContext<any, any>, langCode: any) {
    // @ts-ignore
    commit(SET_LANGUAGE, langCode)
  },
  [TOGGLE_SIDEBAR]({ commit, state }: ActionContext<any, any>) {
    commit(SET_SIDEBAR_STATE, !state.sidebarOpen)
  },
  [TOGGLE_FILTER]({ commit, state }: ActionContext<any, any>) {
    commit(SET_FILTER_STATE, !state.filterOpen)
  },
  [SHOW_FILTER]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_FILTER_STATE, true)
  },
  [TOGGLE_INFO]({ commit, state }: ActionContext<any, any>) {
    commit(SET_FILTER_STATE, !state.filterOpen)
  },
  [CLOSE_FILTER]({ commit }: ActionContext<any, any>) {
    commit(SET_FILTER_STATE, false)
  },
  [TOGGLE_MOBILE_AVAILABILITY_MENU]({ commit, state }: ActionContext<any, any>) {
    commit(SET_MOBILE_AVAILABILITY_MENU, !state.mobileAvailabilityMenuOpen)
  },
  [CHANGE_MOBILE_AVAILABILITY_MENU]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_MOBILE_AVAILABILITY_MENU, payload)
  },
  [CHANGE_SIDEBAR_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_SIDEBAR_STATE, payload)
  },
  [CLOSE_SIDEBAR_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_SIDEBAR_STATE, false)
  },
  [CHANGE_FILTER_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_FILTER_STATE, payload)
  },
  async [LOAD_METADATA]({ commit, rootState }: ActionContext<any, any>) {
    let response

    // @ts-ignore / TODO - rethink the eslint rule
    const apiUrl = this.$config.API_URL

    let url = 'https://' + apiUrl
    if (apiUrl.includes('localhost')) {
      url = 'http://' + apiUrl
    }
    response = await (this.$axios as any).$get(`${url}/landlord/config`)

    const config = response.result
    response = await (this.$axios as any).$get(`${url}/landlord/details`)
    const details = response.result;
    config.landlordDetails = details

    // if (payload.general.customJavascriptEnabled) {
    //   payload.general.customJavascript = ''
    // }
    commit(SET_METADATA, { metaData: config, rootState })
    return {config, details}
  },
  [CHANGE_PAGE_LOADING_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_PAGE_LOADING_STATE, payload)
  },
  [CHANGE_DOCUMENT_READY_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_DOCUMENT_READY_STATE, payload)
  },
  [TOGGLE_SHOW_LEASED_SPACES]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_SHOW_LEASED_SPACES, payload)
  }
}
