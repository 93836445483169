import { ActionContext } from 'vuex'
import constants from './-constants'
import { WebRequest } from '~/helpers/api'
import requestOfferConstants from '~/store/requestOffer/-constants'
import baseConstants from '~/store/base/-constants'

const {
  LOAD_LANDLORD_DETAILS,
  LOAD_PROJECT_AVAILABILITY,
  CHANGE_LOADING_STATE,
  CHANGE_ACTIVE_PROJECT,
  CHANGE_FILTER_STATE,
  RESET_FILTERS,
  RESET_FLOOR_FILTER,
  CLEAR_AMENITIES,
  LOAD_TOTAL_AVAILABLE_RESULTS,
  CHANGE_CURRENT_SPACE,
  UPDATE_SURFACE_MULTIPLIER,
  UPDATE_SURFACE_NAME
} = constants.action
const {
  SET_LANDLORD_DETAILS,
  SET_PROJECT_AVAILABILITY,
  SET_LOADING_STATE,
  SET_PROJECT_LOADING_STATE,
  SET_ACTIVE_PROJECT,
  SET_FILTER_STATE,
  SET_EMPTY_FILTERS,
  SET_TOTAL_AVAILABLE_RESULTS,
  SET_CURRENT_SPACE,
  SET_SURFACE_MULTIPLIER,
  SET_SURFACE_NAME
} = constants.mutation

export default {
  [LOAD_LANDLORD_DETAILS]({ commit, dispatch, rootGetters, rootState }: ActionContext<any, any>) {
    const baseState = rootState.base
    const changePageLoading = (value: boolean) => {
      if (baseState.documentIsReady) {
        dispatch(
          baseConstants.withNamespace(baseConstants.action.CHANGE_PAGE_LOADING_STATE),
          value,
          { root: true }
        )
      }
    }
    commit(SET_LOADING_STATE, true)
    changePageLoading(true)
    return new Promise((resolve, reject) => {
      // @ts-ignore / TODO - rethink the eslint rule
      WebRequest.GET(this.$webApi.landlord.details())
        .then((res: any) => {
          commit(SET_LANDLORD_DETAILS, res.data.result)
          commit(SET_LOADING_STATE, false)
          resolve({
            result: res,
            error: null
          })
          if (res.data.result.projects.length) {
            const defaultProject = { ...res.data.result.projects[0] }
            const router = (this as any).$router
            const currentRoute = router?.app?.$route || { meta: {}, params: [] }
            if (
              (
                [
                  'project-project',
                  'project-project-space-space',
                  'embed-v2-project',
                  'embed-v2-project-space-space',
                  'embed-project',
                  'embed-project-space-space'
                ].includes(currentRoute.name)
                || currentRoute.meta.isProjectStaticPage
              )
              && (
                ('projectId' in currentRoute.params && currentRoute.params.projectId)
                || ('project' in currentRoute.params && currentRoute.params.project)
              )
            ) {
              const foundProject = res.data.result.projects.find(
                (item: any) => item.slug === currentRoute.params.projectId || item.slug === currentRoute.params.project
              )
              if (foundProject) {
                commit(SET_ACTIVE_PROJECT, foundProject.slug)
                dispatch(LOAD_PROJECT_AVAILABILITY, foundProject.id)
              } else {
                commit(SET_ACTIVE_PROJECT, defaultProject.slug)
                dispatch(LOAD_PROJECT_AVAILABILITY, defaultProject.id)
                router.push({ name: 'NotFound', params: {} })
              }
            } else {
              commit(SET_ACTIVE_PROJECT, defaultProject.slug)
              dispatch(LOAD_PROJECT_AVAILABILITY, defaultProject.id)
            }
          } else {
            changePageLoading(false)
          }
        })
        .catch((e: any) => {
          commit(SET_LOADING_STATE, false)
          changePageLoading(false)
          reject(new Error(e.message))
        })
    })
  },
  // eslint-disable-next-line require-await
  async [LOAD_PROJECT_AVAILABILITY](
    { commit, dispatch, rootGetters, rootState }: ActionContext<any, any>,
    payload: any
  ) {
    commit(SET_PROJECT_LOADING_STATE, true)
    const baseState = rootState.base
    const changePageLoading = (value: boolean) => {
      if (baseState.documentIsReady) {
        dispatch(baseConstants.withNamespace(baseConstants.action.CHANGE_PAGE_LOADING_STATE), value, { root: true })
      }
    }
    return new Promise((resolve, reject) => {
      WebRequest.GET(
        (this as any).$webApi.landlord.availability(payload))
        .then((res: any) => {
          commit(SET_PROJECT_AVAILABILITY, res.data.result)
          commit(SET_PROJECT_LOADING_STATE, false)
          changePageLoading(false)
          resolve({
            result: res,
            error: null
          })
        })
        .catch((e: any) => {
          commit(SET_PROJECT_LOADING_STATE, false)
          changePageLoading(false)
          reject(new Error(e.message))
        })
    })
  },
  [CHANGE_LOADING_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_LOADING_STATE, payload)
  },
  [CHANGE_ACTIVE_PROJECT]({ commit, dispatch, state }: ActionContext<any, any>, payload: string) {
    commit(SET_ACTIVE_PROJECT, payload)
    commit(SET_PROJECT_LOADING_STATE, true)
    const projectData = state.projects.find((project: any) => project.slug === payload)
    return new Promise((resolve, reject) => {
      // @ts-ignore / TODO - rethink the eslint rule
      WebRequest.GET(this.$webApi.landlord.availability(projectData.id))
        .then((res: any) => {
          commit(SET_PROJECT_AVAILABILITY, res.data.result)
          commit(SET_PROJECT_LOADING_STATE, false)
          dispatch(
            requestOfferConstants.withNamespace(requestOfferConstants.action.CLEAR_STATE),
            null,
            { root: true }
          )
          resolve({
            result: res,
            error: null
          })
        })
        .catch((e: any) => {
          commit(SET_PROJECT_LOADING_STATE, false)
          reject(new Error(e.message))
        })
    })
  },
  [CHANGE_FILTER_STATE]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_FILTER_STATE, payload)
  },
  [CHANGE_CURRENT_SPACE]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_CURRENT_SPACE, payload)
  },
  [RESET_FILTERS]({ commit }: ActionContext<any, any>) {
    commit(SET_EMPTY_FILTERS, {
      building: -1,
      location: 'all',
      floor: -1,
      floors: [],
      noRooms: -1,
      orientation: 'all',
      priceRange: [],
      surfaceRange: [],
      amenities: [],
      showSoldApartments: false,
    })
  },
  [RESET_FLOOR_FILTER]({ commit }: ActionContext<any, any>) {
    commit(SET_EMPTY_FILTERS, {
      floors: []
    })
  },
  [CLEAR_AMENITIES]({ commit }: ActionContext<any, any>) {
    commit(SET_EMPTY_FILTERS, {
      amenities: [],
      lakeView: false,
      balcony: false
    })
  },
  [LOAD_TOTAL_AVAILABLE_RESULTS]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_TOTAL_AVAILABLE_RESULTS, payload)
  }
  ,
  [UPDATE_SURFACE_MULTIPLIER]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_SURFACE_MULTIPLIER, payload)
  }
  ,
  [UPDATE_SURFACE_NAME]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_SURFACE_NAME, payload)
  }
}
